import React,{ Fragment, Component } from 'react';
import { icons } from '../constants';
import { ThemeContext, ThemeConsumer } from '../context/Theme';

class Header extends Component {

    static contextType = ThemeContext;
    
    constructor(props) {
        super(props);
        this.state = {
            cityselectermodal:false
        };

        this.toggleCitySelecterModal = this.toggleCitySelecterModal.bind(this);
    }

    toggleCitySelecterModal() {

        this.setState({
            cityselectermodal: !this.state.cityselectermodal
        });
    }

    componentDidMount(){

    }

    componentWillUnmount(){

    }

    render(){

        return (
            <ThemeConsumer>{(value)=>{
                
                const{toggleRegionpopup, toggleLoginSignupPopup, toggleHomeSitePopup} = value;

                return(
                    <Fragment>
                        <header className="layout__header ">
                            <div className="layout__header--wrapper">
                                <div className="dv-header">
                                    <div className="dv-header__left">
                                        <div className="dv-header__mobile-logo"><a href="/"><img src={icons.logomobile} alt="Orlo Delivery" /></a></div>
                                        <div className="dv-header__desktop-logo"><a href="/"><img src={icons.logo} alt="Orlo Delivery" /></a></div>
                                        <div className="dv-header__region">
                                            <div className="dv-region-select" onClick={()=>{
                                                toggleRegionpopup()
                                            }}>
                                                <div className="dv-region-select__selector dv-region-select__selector-global">
                                                    <div className="dv-region-select__point">
                                                        <span className="SvgIcon_Root_RpyYp"
                                                            style={{fontSize:'18px'}}>
                                                            <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" className="SvgIcon_Icon_XeBqC" fill="currentColor" focusable="false">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                    d="M9 9.75a2.628 2.628 0 01-2.625-2.625A2.628 2.628 0 019 4.5a2.628 2.628 0 012.625 2.625A2.628 2.628 0 019 9.75zM9 1.5c-3.308 0-6 2.665-6 5.942 0 4.106 5.287 8.684 5.512 8.877a.748.748 0 00.976 0C9.713 16.127 15 11.548 15 7.442 15 4.165 12.308 1.5 9 1.5z">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="dv-region-select__region">Delhi / NCR</div>
                                                    <div className="dv-region-select__seo"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dv-header__menu">
                                        <div className="dv-header__desktop-menu">
                                            <nav className="dv-desktop-menu">
                                                <div
                                                    className="dv-desktop-menu__item dv-desktop-menu__item--link unique-desktop-navigation-call-me">
                                                    <a href="#">Become a New Customer</a></div>
                                                <div className="dv-desktop-menu__item dv-desktop-menu__item--button"><a
                                                        href="order.html"><span className="Button_Wrapper_1jsIr"><button
                                                                className="Button_Button_1tpBy DesktopMenu_Button_PJhzb Button_ButtonSmall_zYd6D Button_Flash_1bhZb Button_Rect_3x530"
                                                                type="button"
                                                                style={{'--color':'hsl(223,100%,50%)', '--color-hover':'hsl(223,100%,35%)'}}><span
                                                                    className="Button_Content_2MgbS">Order
                                                                    Delivery</span></button></span></a></div>
                                                <div className="dv-desktop-menu__item dv-desktop-menu__item--button"><span
                                                        className="Button_Wrapper_1jsIr"><button
                                                            className="Button_Button_1tpBy DesktopMenu_Button_PJhzb unique-desktop-navigation-login Button_ButtonSmall_zYd6D Button_Flash_1bhZb Button_Rect_3x530"
                                                            type="button"
                                                            style={{'--color':'hsl(223,100%,50%)', '--color-hover':'hsl(223,100%,35%)'}} onClick={()=>{toggleHomeSitePopup(true)}}><span
                                                                className="Button_Content_2MgbS">Login / Signup</span></button></span></div>
                                            </nav>
                                        </div>
                                        <div className="dv-header__mobile-menu">
                                            <div className="dv-mobile-menu">
                                                <div className="dv-mobile-menu__menu unique-mobile-navigation-open">
                                                    <span
                                                        className="SvgIcon_Root_RpyYp"
                                                        style={{fontSize:'21px', color:'rgb(150, 148, 147)'}}><svg viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg" className="SvgIcon_Icon_XeBqC"
                                                            fill="currentColor" focusable="false">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M3.054 8h17.892c.58 0 1.054.427 1.054.949v.102c0 .522-.474.949-1.054.949H3.054C2.474 10 2 9.573 2 9.051v-.102C2 8.427 2.474 8 3.054 8zm0 6h17.892c.58 0 1.054.427 1.054.949v.102c0 .522-.474.949-1.054.949H3.054C2.474 16 2 15.573 2 15.051v-.102c0-.522.474-.949 1.054-.949z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                    </Fragment>
                )

            }}
            </ThemeConsumer>
        )
    }
}

export default Header;
export const logomobile = require("../assets/img/global/logo-mobile.svg");
export const logo = require("../assets/img/global/logo.svg");
export const logowhite = require("../assets/img/global/logo-white.svg");
export const wallet = require("../assets/img/global/index/icons/delivery-tools/wallet.svg");
export const clock = require("../assets/img/global/index/icons/delivery-tools/clock.svg");
export const tools = require("../assets/img/global/index/icons/delivery-tools/tools.svg");

export default {
    logomobile,
    logo,
    logowhite,
    wallet,
    clock,
    tools,
}
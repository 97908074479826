import React,{Fragment,Component} from 'react';
import axios from "axios";
import '../assets/css/signin-signup.css';
import { ThemeContext, ThemeConsumer } from '../context/Theme';

export default class LoginPopup extends Component{

   static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {
            usertype: 'individual',
            phone: '',
            email: '',
            password: '',
            message: '',
            alertcolor: '',
            isprocessing: false,
            errorinfo:{},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){

    }

    check_email = (elementValue) =>{
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(elementValue);
    }

    check_phone = (phone) => {
      const regx = /^[6-9]\d{9}$/;
      return regx.test(phone);
    }

    handleUserInput = (e) => {

      let temperrorinfo = this.state.errorinfo;

      const name        = e.target.name;
      const value       = e.target.value;
      const placeholder = e.target.placeholder;

      let msg       = "";
      let haserror  = false;

      if(name === "email")
      {
        if(value !== "")
        {
          if(!this.check_email(value))
          {
            msg       = `Please enter a valid ${placeholder}`;
            haserror  = true;
          }
        }
        else
        {
          if(value === "")
          {
            msg       = `Please enter your ${placeholder}`;
            haserror  = true;
          }
        }
      }
      else if(name !== 'isagreetoterm')
      {
        if(value === "")
        {
          msg       = `Please enter your ${placeholder}`;
          haserror  = true;
        }
      }

      if(name === "phone")
      {
        if(value !== "")
        {
          if(!this.check_phone(value))
          {
            msg       = `Please enter a valid ${placeholder}`;
            haserror  = true;
          }
        }
      }

      if(name !== 'isagreetoterm')
      {
        temperrorinfo   = {...temperrorinfo, [name+'msg']:msg,['has'+name+'error']:haserror};
      }

      this.setState({
          [name]: value,
          errorinfo:temperrorinfo,
      });
    }

    handleSubmit(e) {
      e.preventDefault();

      const{ usertype, email, password, phone, errorinfo} = this.state;

      let haserror      = false;
      let temperrorinfo = errorinfo;

      if(usertype === 'individual')
      {
        if(phone === "")
        {
          haserror    = true;
          temperrorinfo   = {...temperrorinfo, phonemsg:"Please enter phone number",hasphoneerror:true};
        }
        else if(phone !== "" && !this.check_phone(phone))
        {
          haserror    = true;
          temperrorinfo   = {...temperrorinfo, phonemsg:"Please enter valid phone number",hasphoneerror:true};
        }
        else
        {
          temperrorinfo   = {...temperrorinfo, phonemsg:"",hasphoneerror:false};
        }
      }
      else
      {
        if(email === "")
        {
          haserror    = true;
          temperrorinfo   = {...temperrorinfo, emailmsg:"Please enter your email",hasemailerror:true};
        }
        else if(email !== "" && !this.check_email(email))
        {
          haserror    = true;
          temperrorinfo   = {...temperrorinfo, emailmsg:"Please enter a valid email",hasemailerror:true};
        }
        else
        {
          temperrorinfo   = {...temperrorinfo, emailmsg:"",hasemailerror:false};
        }
      }

      if(password === "")
      {
        haserror    = true;
        temperrorinfo   = {...temperrorinfo, passwordmsg:"Please enter password",haspassworderror:true};
      }
      else
      {
        temperrorinfo   = {...temperrorinfo, passwordmsg:"",haspassworderror:false};
      }

      if(haserror)
      {
        this.setState({
            errorinfo:temperrorinfo
        });
        return false;
      }
      else
      {
        temperrorinfo   = {...temperrorinfo, emailmsg:"",hasemailerror:false, phonemsg:"", hasphoneerror:false, passwordmsg:"", haspassworderror:false};
        this.setState({
            errorinfo:temperrorinfo
        });
      }

      this.setState(()=>{
        return{
          message:'Processing...',
          alertcolor:'primary',
          isprocessing:false
        }
      },()=>{
        setTimeout(()=>{
          const bodyFormData = new FormData();
          bodyFormData.set('Mode', 'Register');
          bodyFormData.set('usertype', usertype);
          bodyFormData.set('phone', phone);
          bodyFormData.set('email', email);
          bodyFormData.set('password', password);
    
          axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}register.php`,
              data: bodyFormData,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{

              this.setState({
                isprocessing:false,
                message:'',
              });

              let color   = "danger";
              
              if(response.data.success === true)
              {
                localStorage.setItem(`orlo_${process.env.REACT_APP_PREFIX}_users`,JSON.stringify(response.data.detail));

                color   = "success";
  
                /*this.props.history.push(`${response.data.redirecturl}`);*/
                this.props.history.push(`/cabinet`);
              }

              /*this.showMessage(response.data.msg,color);*/

            })
            .catch(function (response) {
              //handle error
              console.log(response);
          });

        },1500);
      })
    }

	render(){

        const{ errorinfo, usertype, email, phone, password } = this.state;

      return (
         <ThemeConsumer>{(value)=>{

            const{changePopupTypeWidth} = value;
               
            return (
              <Fragment>
                <div className="common_RowTopMargin_3Yu5c common_Row_mq_ng">
                    <div className="TabSelect_Root_3MOqV">
                        <div className="TabSelect_Group_1_GG2 TabSelect_Responsive_3KxU1 TabSelect_GroupFullWidth_1paLb TabSelect_GroupFullWidth_1paLb" tabIndex="0" role="radiogroup">
                            <div className={`unique-mobile-signin-signup-person UserTypeSelect_Tab_3-WAU TabSelect_TabText_2abdK TabSelect_ContinuousTab_2opoQ TabSelect_TabBase_jlehW TabSelect_Responsive_3KxU1 ${usertype === 'individual' ? 'TabSelect_ContinuousTabActive_1KByI':''}`} role="radio" aria-hidden="true" aria-checked="true" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}} onClick={()=>{
                                this.setState({
                                    usertype:'individual',
                                    errorinfo:{}
                                })
                            }}>
                                <p className="Typography_Paragraph_2t3g9 Typography_p16_2F4ZL Typography_normal_1bwER">For individuals</p>
                            </div>
                            <div className={`unique-mobile-signin-signup-company UserTypeSelect_Tab_3-WAU TabSelect_TabText_2abdK TabSelect_ContinuousTab_2opoQ TabSelect_TabBase_jlehW TabSelect_Responsive_3KxU1 ${usertype === 'business' ? 'TabSelect_ContinuousTabActive_1KByI':''}`} role="radio" aria-hidden="true" aria-checked="false" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}} onClick={()=>{
                                this.setState({
                                    usertype:'business',
                                    errorinfo:{}
                                })
                            }}>
                                <p className="Typography_Paragraph_2t3g9 Typography_p16_2F4ZL Typography_normal_1bwER">For business</p>
                            </div>
                        </div>
                    </div>
                </div>
                <form method="post" onSubmit={this.handleSubmit}>
                {
                  usertype === 'individual' ? (
                    <div className="common_Row_mq_ng">
                        <div className={`Input_Root_2mmBV ${phone !== "" ?`Input_Filled_eU9fm Input_WithPrefix_3ymyc`:``} ${errorinfo.hasphoneerror ? `Input_error_i-YyB dv-scroll-error-marker`:''}`}>
                            <div className="Input_Wrapper_g4Kqw">
                                <div className="Input_InputWrapper_3N0O5">
                                {phone !== "" ? <div className="Input_Prefix_AFgXf">+91</div>:null}<input className="Input_Field_3rwEh" autocomplete="username" inputmode="tel" placeholder="phone" name="phone" id="phone" value={phone} onChange={this.handleUserInput} /><label className="Input_Label_2fEDE">Phone</label>
                                </div>
                            </div>
                          {
                            errorinfo.phonemsg ? (<span class="InlineError_Root_19Zvx">{errorinfo.phonemsg}</span>):''
                          }
                        </div>
                    </div>
                  ):(
                    <div className="common_Row_mq_ng">
                        <div className={`Input_Root_2mmBV ${email !== "" ?`Input_Filled_eU9fm`:``} ${errorinfo.hasemailerror ? `Input_error_i-YyB dv-scroll-error-marker`:''}`}>
                        <div className="Input_Wrapper_g4Kqw">
                            <div className="Input_InputWrapper_3N0O5"><input className="Input_Field_3rwEh unique-registration-person-modal__password" placeholder="email" type="email" autoComplete="email" name="email" id="email" value={email} onChange={this.handleUserInput} /><label className="Input_Label_2fEDE">Email</label></div>
                        </div>
                        {
                          errorinfo.emailmsg ? (<span class="InlineError_Root_19Zvx">{errorinfo.emailmsg}</span>):''
                        }
                        </div>
                    </div>
                  )
                }
                    <div className="common_Row_mq_ng">
                        <div className={`Input_Root_2mmBV ${password !== "" ?`Input_Filled_eU9fm`:``} ${errorinfo.haspassworderror ? `Input_error_i-YyB dv-scroll-error-marker`:''}`}>
                        <div className="Input_Wrapper_g4Kqw">
                            <div className="Input_InputWrapper_3N0O5"><input className="Input_Field_3rwEh unique-registration-person-modal__password" type="password" autoComplete="new-password" name="password" id="password" value={password} onChange={this.handleUserInput} /><label className="Input_Label_2fEDE">Password</label></div>
                        </div>
                        {
                          errorinfo.passwordmsg ? (<span class="InlineError_Root_19Zvx">{errorinfo.passwordmsg}</span>):''
                        }
                        </div>
                    </div>
                    <div className="common_RowForgot_1g9nK common_Row_mq_ng">
                      <span className="Button_Wrapper_1jsIr"><button className="Button_Button_1tpBy common_Forgot_3oCsb Button_ButtonMedium_3seGY Button_Link_gDHo3" type="button" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}} onClick={()=>{changePopupTypeWidth('forgotpassword','450px')}}>
                      <span className="Button_Content_2MgbS">Forgot password?</span></button></span></div>
                    <div className="common_Row_mq_ng"><span className="Button_Wrapper_1jsIr" style={{width:'100%'}}><button className="Button_Button_1tpBy common_Button_17HA4 Button_ButtonMedium_3seGY Button_Primary_2qt9c Button_Rect_3x530" type="submit" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}}><span className="Button_Content_2MgbS">Log in</span></button></span></div>
                </form>
                <div className="common_Buttons_1sjHn"><span className="Button_Wrapper_1jsIr">
                    <button className="Button_Button_1tpBy common_Button_17HA4 unique-mobile-signin-signup-signup Button_ButtonMedium_3seGY Button_Link_gDHo3" type="button" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}}onClick={()=>{changePopupTypeWidth('signup','450px')}}><span className="Button_Content_2MgbS">Sign up</span>
                    </button></span>
                </div>                
              </Fragment>
            );
         }}
         </ThemeConsumer>
      )
	}
}
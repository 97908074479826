import React,{Component} from 'react';
import { Modal } from 'react-responsive-modal';
import { ThemeContext, ThemeConsumer } from '../context/Theme';

export default class Cityselecterpopup extends Component{

   static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {
			regions:[{id:1,name:'Delhi/NCR'},{id:2,name:'Mumbai'},{id:3,name:'Bengaluru'},{id:4,name:'Hyderabad'},{id:5,name:'Ahmedabad'},{id:6,name:'Chennai'},{id:7,name:'Kolkata'},{id:8,name:'Pune'},{id:9,name:'Surat'},{id:10,name:'Goa'},{id:11,name:'Chandigarh'}]
        };
    }
    componentDidMount(){

    }

	render(){

		const {regions} = this.state;

      return (
         <ThemeConsumer>{(value)=>{
               
            const{toggleRegionpopup} = value;
            const{isregionpopup} = value;

         return (

            <Modal open={isregionpopup} onClose={()=>{toggleRegionpopup()}} classNames={{
               overlay: 'Modal_Overlay_1DSkx',
               modalContainer: 'Modal_ModalContainer_2VcT3',
               modal: 'Modal_ModalBlock_1MOPc',
               closeButton:'Modal_CloseButton_1ebrY'
            }}
            styles={{
               modal:{animation:'0ms ease 0s 1 normal none running react-responsive-modal-modal-in', width:'640px'}
            }}>

               <div className="RegionDesktop_Header_1RZBH">
                  <h4 className="Typography_Heading_1oC-m Typography_h4_fO1j4 Typography_bold_3_Cf-">Your region</h4>
                  <div className="RegionDesktop_Country_1C-Dn">
                     <div className="CountryPopup_Root_1Pky3">
                        <button type="button" className="CountryButton_Country_3qDF-">
                           <div className="CountryButton_Flag_2mGdQ">
                              <span className="SvgIcon_Root_RpyYp" style={{fontSize:'24px'}}>
                                 <svg width="24" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="SvgIcon_Icon_XeBqC" focusable="false">
                                    <g clipPath="url(#india_svg__clip0)">
                                       <path d="M1 4h22v5.5H1V4z" fill="#F93"></path>
                                       <path d="M1 9.5h22V15H1V9.5z" fill="#fff"></path>
                                       <path d="M1 15h22v5.5H1V15z" fill="#128807"></path>
                                       <path d="M1 4h24v16H1V4z" fill="#F93"></path>
                                       <path d="M1 9.333h24V20H1V9.333z" fill="#fff"></path>
                                       <path d="M12.133 14.133a2.133 2.133 0 100-4.266 2.133 2.133 0 000 4.266z" fill="#008"></path>
                                       <path d="M12.133 13.867a1.867 1.867 0 100-3.734 1.867 1.867 0 000 3.734z" fill="#fff"></path>
                                       <path d="M12.133 12.373a.373.373 0 100-.746.373.373 0 000 .746z" fill="#008"></path>
                                       <path d="M12.133 13.867l.064-1.12-.064-.534-.064.534.064 1.12zM12.133 10.133l.064 1.12-.064.534-.064-.534.064-1.12zM10.267 12l1.12.064.533-.064-.533-.064-1.12.064zM14 12l-1.12.064-.533-.064.533-.064L14 12zM10.358 11.423l1.045.407.527.104-.487-.226-1.085-.285zM13.909 12.577l-1.085-.285-.488-.226.527.104 1.046.407zM10.623 10.903l.869.71.469.262-.394-.366-.944-.606zM13.643 13.097l-.943-.606-.394-.366.469.262.868.71zM11.036 10.49l.607.944.365.393-.262-.469-.71-.868zM13.23 13.51l-.71-.868-.261-.47.365.394.606.944zM11.556 10.225l.286 1.085.225.487-.104-.527-.406-1.045zM12.71 13.775l-.407-1.045-.104-.527.226.487.285 1.085zM13.909 11.423l-1.046.407-.527.104.488-.226 1.085-.285zM10.358 12.577l1.085-.285.487-.226-.527.104-1.045.407zM13.643 10.903l-.868.71-.47.262.395-.366.944-.606zM10.623 13.097l.944-.606.394-.366-.47.262-.868.71zM13.23 10.49l-.606.944-.365.393.261-.469.71-.868zM11.036 13.51l.71-.868.262-.47-.365.394-.607.944zM12.71 10.225l-.285 1.085-.226.487.104-.527.407-1.045zM11.556 13.775l.408-1.045.103-.527-.225.487-.286 1.085z" fill="#008"></path>
                                       <path d="M1 14.667h24V20H1v-5.333z" fill="#128807"></path>
                                    </g>
                                    <defs>
                                       <clipPath id="india_svg__clip0">
                                          <rect x="1" y="4" width="22" height="16.5" rx="4" fill="#fff"></rect>
                                       </clipPath>
                                    </defs>
                                 </svg>
                              </span>
                           </div>
                           <span className="CountryButton_CountryName_T21o2">India</span>
                        </button>
                     </div>
                  </div>
               </div>
               <div className="RegionDesktop_List_3FmZR" style={{height:'calc(378px)'}}>
               {
                  regions.map((region, loop)=>{
                     return(
                        <div key={loop} className={`RegionDesktop_RegionWrapper_R8l7y`}><button className={`RegionDesktop_Region_xbi8A ${region.id ===1 ? 'RegionDesktop_RegionSelected_1vbtm':''}`} type="button">{region.name}</button></div>
                     )
                  })
               }
               </div>
            </Modal>
         );         

         }}
         </ThemeConsumer>
      )
	}
}
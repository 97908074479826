import React,{ Fragment, Component } from 'react';
import { icons, images } from '../constants';
import {Header, Cityselecterpopup, SignupPopup, LoginPopup, Sitepopup, Footer} from '../components';

class Home extends Component {

    /*static contextType = AuthContext;*/
    
    constructor(props) {
        super(props);
        this.state = {
            pickupaddress:'',
            from_address:'',
            deliveryaddress:'',
            to_address:'',
            message:'',
            alertcolor:'',
            errorinfo:{}
        };
    }

    componentDidMount(){

    }

    handleUserInput = (e) => {

        let temperrorinfo = this.state.errorinfo;

        const name        = e.target.name;
        const value       = e.target.value;
        const placeholder = e.target.placeholder;

        let msg       = "";
        let haserror  = false;

        if(value === "")
        {
          msg       = `Please enter your ${placeholder}`;
          haserror  = true;
        }

        if(name === "phone")
        {
          if(value !== "")
          {
            if(!this.check_phone(value))
            {
              msg       = `Please enter a valid ${placeholder}`;
              haserror  = true;
            }
          }
        }

        temperrorinfo   = {...temperrorinfo, [name+'msg']:msg,['has'+name+'error']:haserror};

        this.setState({
            [name]: value,
            errorinfo:temperrorinfo,
        });
    }

    handleSubmit(e) {

    }

    showMessage=(msg,color)=>{
        this.setState({
            message:msg,
            alertcolor:color
        },()=>{
            setTimeout(()=>{
                this.setState({
                    message:'',
                    alertcolor:''
                });
            },3000);
        });
    }

    render(){

        const{ pickupaddress, from_address, deliveryaddress, to_address } = this.state;

        return(
        <Fragment>
            <div className="layout">
                <Header />
                <main className="layout__content">
                    <div className="layout__content-inner">
                        <section className="dv-header-promo">
                            <div className="dv-header-promo__wrapper">
                                <div className="dv-header-promo__image "> <img src={images.walkman} alt="promo-image" />
                                    <div className="dv-header-promo__sticker"> </div>
                                </div>
                                <div className="dv-header-promo__content">
                                    <h1 className="dv-header-promo__title"> Fastest Courier Service in Mumbai </h1>
                                    <div className="dv-header-promo__subtitle">Low-priced same day delivery service!</div>
                                    <div className="dv-header-promo__mini-order">
                                        <div className="dv-mini-order-form-widget--js">
                                            <div className="LandingMiniFormGlobal_Root_S-64A">
                                                <form className="LandingMiniFormGlobal_Form_3iHT-" action="/in/order" method="get">
                                                    <div className="LandingMiniFormGlobal_Row_ond5L">
                                                        <div className="withSuggestionList_Root_2uaNI">
                                                            <div className="LandingMiniFormAddress_Global_3lGSn"><svg
                                                                    className="LandingMiniFormAddress_Icon_3kobq" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z">
                                                                    </path>
                                                                    <mask id="maskPoint2" mask-type="alpha"
                                                                        maskUnits="userSpaceOnUse" x="4" y="2" width="16"
                                                                        height="20">
                                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                                            d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z"
                                                                            fill="white"></path>
                                                                    </mask>
                                                                </svg><input type="text" autoComplete="off"
                                                                    placeholder="Pick up Address"
                                                                    className="LandingMiniFormAddress_IsGlobal_mlus8 LandingMiniFormAddress_Input_1r_N0 unique-mini-form__address-from"
                                                                    name="pickupaddress" id="pickupaddress" value={pickupaddress} onChange={this.handleUserInput} /></div>
                                                            <div className="withSuggestionList_List_2-DQy">
                                                                <div className="SelectableListView_List_N4no-"
                                                                    style={{maxHeight:'300px'}}></div>
                                                            </div>
                                                        </div><input type="hidden" name="from_address" id="from_address" value={from_address} onChange={this.handleUserInput}/>
                                                    </div>
                                                    <div className="LandingMiniFormGlobal_Row_ond5L">
                                                        <div className="withSuggestionList_Root_2uaNI">
                                                            <div className="LandingMiniFormAddress_Global_3lGSn"><svg
                                                                    className="LandingMiniFormAddress_Icon_3kobq" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                                        d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z">
                                                                    </path>
                                                                    <mask id="maskPoint2" mask-type="alpha"
                                                                        maskUnits="userSpaceOnUse" x="4" y="2" width="16"
                                                                        height="20">
                                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                                            d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z"
                                                                            fill="white"></path>
                                                                    </mask>
                                                                </svg><input type="text" autoComplete="off"
                                                                    placeholder="Delivery address"
                                                                    className="LandingMiniFormAddress_IsGlobal_mlus8 LandingMiniFormAddress_Input_1r_N0 unique-mini-form__address-to" name="deliveryaddress" id="deliveryaddress" value={deliveryaddress} onChange={this.handleUserInput} /></div>
                                                            <div className="withSuggestionList_List_2-DQy">
                                                                <div className="SelectableListView_List_N4no-"
                                                                    style={{maxHeight:'300px'}}></div>
                                                            </div>
                                                        </div><input type="hidden" name="to_address" id="to_address" value={to_address} onChange={this.handleUserInput} />
                                                    </div>
                                                    <div
                                                        className="LandingMiniFormGlobal_TopPadding_1ZikS LandingMiniFormGlobal_Row_ond5L">
                                                        <span className="Button_Wrapper_1jsIr" style={{width:'100%'}}><button
                                                                className="Button_Button_1tpBy unique-mini-form__submit-address Button_ButtonMedium_3seGY Button_Primary_2qt9c Button_Rect_3x530"
                                                                type="submit"
                                                                style={{'--color':'hsl(223,100%,50%)', '--color-hover':'hsl(223,100%,35%)'}}><span
                                                                    className="Button_Content_2MgbS">Book a
                                                                    courier</span></button></span></div>
                                                    <div className="LandingMiniFormGlobal_Description_2Vru6">Book without registering, urgent delivery at no extra cost</div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dv-header-promo__mini-order dv-header-promo__mini-order--mobile">
                                <div className="dv-mini-order-form-widget--js">
                                    <div className="LandingMiniFormGlobal_Root_S-64A">
                                        <form className="LandingMiniFormGlobal_Form_3iHT-" action="/in/order" method="get">
                                            <div className="LandingMiniFormGlobal_Row_ond5L">
                                                <div className="withSuggestionList_Root_2uaNI">
                                                    <div className="LandingMiniFormAddress_Global_3lGSn"><svg
                                                            className="LandingMiniFormAddress_Icon_3kobq" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z">
                                                            </path>
                                                            <mask id="maskPoint2" mask-type="alpha" maskUnits="userSpaceOnUse"
                                                                x="4" y="2" width="16" height="20">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                    d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z"
                                                                    fill="white"></path>
                                                            </mask>
                                                        </svg><input type="text" autoComplete="off"
                                                            placeholder="Pick up Address"
                                                            className="LandingMiniFormAddress_IsGlobal_mlus8 LandingMiniFormAddress_Input_1r_N0 unique-mini-form__address-from"
                                                            value="" /></div>
                                                    <div className="withSuggestionList_List_2-DQy">
                                                        <div className="SelectableListView_List_N4no-" style={{maxHeight:'300px'}}>
                                                        </div>
                                                    </div>
                                                </div><input type="hidden" name="from_address" value="" />
                                            </div>
                                            <div className="LandingMiniFormGlobal_Row_ond5L">
                                                <div className="withSuggestionList_Root_2uaNI">
                                                    <div className="LandingMiniFormAddress_Global_3lGSn"><svg
                                                            className="LandingMiniFormAddress_Icon_3kobq" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z">
                                                            </path>
                                                            <mask id="maskPoint2" mask-type="alpha" maskUnits="userSpaceOnUse"
                                                                x="4" y="2" width="16" height="20">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                    d="M12 10.9995C11.173 10.9995 10.5 10.3265 10.5 9.4995C10.5 8.6725 11.173 7.9995 12 7.9995C12.827 7.9995 13.5 8.6725 13.5 9.4995C13.5 10.3265 12.827 10.9995 12 10.9995ZM12 5.9995C10.07 5.9995 8.5 7.5695 8.5 9.4995C8.5 11.4295 10.07 12.9995 12 12.9995C13.93 12.9995 15.5 11.4295 15.5 9.4995C15.5 7.5695 13.93 5.9995 12 5.9995ZM12 19.646C10.325 18.062 6 13.615 6 9.922C6 6.657 8.691 4 12 4C15.309 4 18 6.657 18 9.922C18 13.615 13.675 18.062 12 19.646ZM12 2C7.589 2 4 5.553 4 9.922C4 15.397 11.049 21.501 11.349 21.758C11.537 21.919 11.768 22 12 22C12.232 22 12.463 21.919 12.651 21.758C12.951 21.501 20 15.397 20 9.922C20 5.553 16.411 2 12 2Z"
                                                                    fill="white"></path>
                                                            </mask>
                                                        </svg><input type="text" autoComplete="off"
                                                            placeholder="Delivery address"
                                                            className="LandingMiniFormAddress_IsGlobal_mlus8 LandingMiniFormAddress_Input_1r_N0 unique-mini-form__address-to"
                                                            value="" /></div>
                                                    <div className="withSuggestionList_List_2-DQy">
                                                        <div className="SelectableListView_List_N4no-" style={{maxHeight:'300px'}}>
                                                        </div>
                                                    </div>
                                                </div><input type="hidden" name="to_address" value="" />
                                            </div>
                                            <div className="LandingMiniFormGlobal_TopPadding_1ZikS LandingMiniFormGlobal_Row_ond5L">
                                                <span className="Button_Wrapper_1jsIr" style={{width:'100%'}}><button
                                                        className="Button_Button_1tpBy unique-mini-form__submit-address Button_ButtonMedium_3seGY Button_Primary_2qt9c Button_Rect_3x530"
                                                        type="submit"
                                                        style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}}><span
                                                            className="Button_Content_2MgbS">Book a courier</span></button></span>
                                            </div>
                                            <div className="LandingMiniFormGlobal_Description_2Vru6">Book without registering, urgent delivery at no extra cost</div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="layout__content-section dv-card-list__layout">
                            <div className="layout__content-wrapper layout__content-wrapper--padding">
                                <div className="layout__content-header"> Delivery at a glance </div>
                                <div className="dv-card-list">
                                    <div className="dv-card-list__card dv-card-list__card--in">
                                        <div className="dv-card-list__image-wrapper"> <img className="dv-card-list__image" src={icons.wallet} /></div>
                                        <div className="dv-card-list__title"> ₹ 40 </div>
                                        <div className="dv-card-list__description "> Start from ₹ 40 for<br />hyperlocal tariff in Mumbai<br />and ₹ 8 per km in zone 1 </div>
                                    </div>
                                    <div className="dv-card-list__card dv-card-list__card--in">
                                        <div className="dv-card-list__image-wrapper"> <img className="dv-card-list__image"
                                                src={icons.clock} /> </div>
                                        <div className="dv-card-list__title"> 90 min </div>
                                        <div className="dv-card-list__description "> We can deliver ASAP<br />or at a specified
                                            time<br />documents, products, flowers, any product </div>
                                    </div>
                                    <div className="dv-card-list__card dv-card-list__card--in">
                                        <div className="dv-card-list__image-wrapper"> <img className="dv-card-list__image"
                                                src={icons.tools} /> </div>
                                        <div className="dv-card-list__title"> API </div>
                                        <div className="dv-card-list__description "> Selling online?<br />Place orders via API<br />Find
                                            the guidelines at<br /><a href="/in/integration">API Docs</a> </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="layout__content-section">
                            <section className="pls-install-app">
                                <div className="pls-install-app__content">
                                    <div className="pls-install-app__circle"></div>
                                    <div className="pls-install-app__circle-background"></div>
                                    <h2 className="pls-install-app__header">Download the app and all couriers will
                                        be&nbsp;at&nbsp;your hand</h2>
                                    <p className="pls-install-app__description">In&nbsp;any free minute, create new orders and
                                        monitor the implementation of&nbsp;already created.</p>
                                    <div className="pls-install-app__links"> <a
                                            href="https://app.adjust.net.in/777qpap?campaign=Internal&amp;adgroup=Main-QR&amp;engagement_type=fallback_click"
                                            className="pls-install-app__qr-code"> <img src={images.global_in} alt="QR-code" /> </a>
                                        <div className="pls-install-app__apps"> <a className="pls-install-app__link"
                                                href="#"
                                                target="_blank"> <img src={images.appstore}
                                                    alt="appstore-badge" className="pls-install-app__link-img" /> </a> <a
                                                className="pls-install-app__link"
                                                href="#"
                                                target="_blank"> <img src={images.playstore}
                                                    alt="google-badge" className="pls-install-app__link-img" /> </a> <a
                                                className="pls-install-app__link"
                                                href="#" target="_blank"> <img
                                                    src={images.appgallery} alt="appgallery-badge"
                                                    className="pls-install-app__link-img" /> </a> </div>
                                    </div> <img src={images.inimg}
                                        className="pls-install-app__img" alt="" />
                                </div>
                            </section>
                        </section>
                        <section className="layout__content-section">
                            <div className="layout__content-wrapper layout__content-wrapper--padding">
                                <div className="layout__content-header"> Why choose us? </div>
                                <div className="dv-small-card-list ">
                                    <ul className="dv-small-card-list__items">
                                        <li className="dv-small-card-list__item">
                                            <div className="dv-small-card-list__image">
                                                <div className="dv-small-card-list__icon--clock"></div>
                                            </div>
                                            <div className="dv-small-card-list__text">Walking couriers and riders are always
                                                available. We assign the nearest courier with the highest rating within 7
                                                minutes.</div>
                                        </li>
                                        <li className="dv-small-card-list__item">
                                            <div className="dv-small-card-list__image">
                                                <div className="dv-small-card-list__icon--rocket"></div>
                                            </div>
                                            <div className="dv-small-card-list__text">The system assigns high-scored and closest
                                                courier. Probably we provide you with fastest delivery service ever.</div>
                                        </li>
                                        <li className="dv-small-card-list__item">
                                            <div className="dv-small-card-list__image">
                                                <div className="dv-small-card-list__icon--rate"></div>
                                            </div>
                                            <div className="dv-small-card-list__text">Special services are available such as buying
                                                from the store, and multi-point deliveries!</div>
                                        </li>
                                        <li className="dv-small-card-list__item">
                                            <div className="dv-small-card-list__image">
                                                <div className="dv-small-card-list__icon--mail"></div>
                                            </div>
                                            <div className="dv-small-card-list__text">If you own a business you can opt for COD with
                                                us. Its cheap and effective. No reason to wait weeks for getting your revenue
                                                back.</div>
                                        </li>
                                        <li className="dv-small-card-list__item">
                                            <div className="dv-small-card-list__image">
                                                <div className="dv-small-card-list__icon--phone"></div>
                                            </div>
                                            <div className="dv-small-card-list__text">We send courier's phone number to the contact
                                                person via SMS at each delivery point.</div>
                                        </li>
                                        <li className="dv-small-card-list__item">
                                            <div className="dv-small-card-list__image">
                                                <div className="dv-small-card-list__icon--sms"></div>
                                            </div>
                                            <div className="dv-small-card-list__text">You can book a courier delivery without
                                                creating accounts or signing contracts. The phone number for the sender and
                                                receiver is more than enough!</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <section className="layout__content-section">
                            <div className="layout__content-wrapper layout__content-wrapper--padding">
                                <div className="payment">
                                    <h3 className="layout__content-header layout__content-header--small-padding"> Payment Methods
                                    </h3>
                                    <div className="payment__subtitle">Pay for courier services in a convenient way:</div>
                                    <div className="payment__body">
                                        <ul className="payment__list">
                                            <li className="payment__item">
                                                <div className="payment__icon payment__icon--card"></div>
                                                <div className="payment__item-subtitle">For everyone</div>
                                                <div className="payment__name">Plastic card</div>
                                            </li>
                                            <li className="payment__item">
                                                <div className="payment__icon payment__icon--cash"></div>
                                                <div className="payment__item-subtitle">For individuals</div>
                                                <div className="payment__name">Cash</div>
                                            </li>
                                            <li className="payment__item">
                                                <div className="payment__icon payment__icon--wallet"></div>
                                                <div className="payment__item-subtitle">For everyone</div>
                                                <div className="payment__name">Add funds to a balance with Razorpay, Paytm or bank
                                                    transfer</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="layout__content-section dv-options-block-without-buttons__layout">
                            <div className="layout__content-wrapper layout__content-wrapper--padding">
                                <div className="layout__content-header"> Available delivery options </div>
                                <div className="dv-options-block-without-buttons">
                                    <div className="dv-options-block-without-buttons__items"> <a href="/in/order?vehicle=public"
                                            className="dv-options-block-without-buttons__item">
                                            <div>
                                                <div className="dv-options-block-without-buttons__image"><img
                                                        src={images.courier}
                                                        alt="Public transport" /></div>
                                                <div className="dv-options-block-without-buttons__title">Public transport</div>
                                            </div>
                                        </a> <a href="/in/order" className="dv-options-block-without-buttons__item">
                                            <div>
                                                <div className="dv-options-block-without-buttons__image"><img
                                                        src={images.kettle}
                                                        alt="Match special requirements" /></div>
                                                <div className="dv-options-block-without-buttons__title">Match special requirements
                                                </div>
                                            </div>
                                        </a> <a href="/in/order?vehicle=motorbike"
                                            className="dv-options-block-without-buttons__item">
                                            <div>
                                                <div className="dv-options-block-without-buttons__image"><img
                                                        src={images.motorbike} alt="Moto" />
                                                </div>
                                                <div className="dv-options-block-without-buttons__title">Moto</div>
                                            </div>
                                        </a> </div>
                                </div>
                            </div>
                        </section>
                        <section className="layout__content-section dv-index-info__layout">
                            <div className="layout__content-wrapper layout__content-wrapper--padding">
                                <div className="layout__content-header layout__content-header--small-padding dv-index-info__header">
                                    Just want to try the service? </div>
                                <div className="dv-index-info">
                                    <div className="dv-index-info__text ">
                                        <p></p>
                                        <div>Book a courier without registration.</div>
                                        <p></p>
                                    </div>
                                    <div className="dv-index-info__button">
                                        <div className="dv-button dv-button--color dv-button--big dv-button--rounded"><a href="/in/order">Book a courier</a></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
                <div id="agree-to-use-cookies"></div>
                <div id="flash-notifications"></div>
                <Footer />
                <div id="signin-signup-modal"></div>
                <div id="region-select-modal"></div>
                <div id="call-me-back-modal"></div>
                <div className="modal-window modal-window--always-on-top" data-modal-name="recaptcha-modal">
                    <div className="modal-window__panel">
                        <div className="modal-window__container ">
                            <div className="modal-window__inner">
                                <div className="modal-window__header"> <span className="modal-window__title">Please confirm that you are not a robot</span> </div>
                                <div className="modal-window__body">
                                    <div className="recaptcha-content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intercom-lightweight-app">
                <div className="intercom-lightweight-app-launcher intercom-launcher" role="button" tabIndex="0"
                    aria-label="Open Intercom Messenger" aria-live="polite">
                    <div className="intercom-lightweight-app-launcher-icon intercom-lightweight-app-launcher-icon-open"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                            <path
                                d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z">
                            </path>
                        </svg></div>
                    <div className="intercom-lightweight-app-launcher-icon intercom-lightweight-app-launcher-icon-minimize"><svg
                            viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M.116 4.884l1.768-1.768L8 9.232l6.116-6.116 1.768 1.768L8 12.768.116 4.884z"></path>
                        </svg></div>
                </div>
            </div>
            <Cityselecterpopup />
            {/*<SignupPopup />*/}
            <Sitepopup />
        </Fragment>
        )

    }
}

export default Home;
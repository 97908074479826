export const walkman = require("../assets/img/global/index/walkman.svg");
export const global_in = require("../assets/img/qr-codes/global_in.png");
export const appstore = require("../assets/img/in/mobile-app/appstore.svg");
export const playstore = require("../assets/img/in/mobile-app/playstore.svg");
export const appgallery = require("../assets/img/in/mobile-app/appgallery.svg");
export const inimg = require("../assets/img/global/index/icons/pls-install-app/in.png");
export const courier = require("../assets/img/global/index/icons/transport/courier.svg");
export const kettle = require("../assets/img/global/index/icons/transport/kettle.svg");
export const motorbike = require("../assets/img/global/index/icons/transport/motorbike.svg");

export default {
    walkman,
    global_in,
    appstore,
    playstore,
    appgallery,
    inimg,
    courier,
    kettle,
    motorbike,
}
import React,{Fragment, Component} from 'react';
import axios from "axios";
import '../assets/css/signin-signup.css';
import { ThemeContext, ThemeConsumer } from '../context/Theme';

export default class ForgotPasswordPopup extends Component{

   static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            smscode: '',
            message: '',
            alertcolor: '',
            isprocessing: false,
            errorinfo:{},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){

    }

    check_phone = (phone) => {
      const regx = /^[6-9]\d{9}$/;
      return regx.test(phone);
    }

    handleUserInput = (e) => {

        let temperrorinfo = this.state.errorinfo;

        const name        = e.target.name;
        const value       = e.target.value;
        const placeholder = e.target.placeholder;

        let msg       = "";
        let haserror  = false;

        if(name === "email")
        {
          if(value !== "")
          {
            if(!this.check_email(value))
            {
              msg       = `Please enter a valid ${placeholder}`;
              haserror  = true;
            }
          }
          else
          {
            if(value === "")
            {
              msg       = `Please enter your ${placeholder}`;
              haserror  = true;
            }
          }
        }
        else if(name !== 'isagreetoterm')
        {
          if(value === "")
          {
            msg       = `Please enter your ${placeholder}`;
            haserror  = true;
          }
        }

        if(name === "phone")
        {
          if(value !== "")
          {
            if(!this.check_phone(value))
            {
              msg       = `Please enter a valid ${placeholder}`;
              haserror  = true;
            }
          }
        }

        if(name !== 'isagreetoterm')
        {
          temperrorinfo   = {...temperrorinfo, [name+'msg']:msg,['has'+name+'error']:haserror};
        }

        this.setState({
            [name]: value,
            errorinfo:temperrorinfo,
        });
    }

    handleSubmit(e) {
      e.preventDefault();

      const{ phone, smscode, errorinfo} = this.state;

      let haserror      = false;
      let temperrorinfo = errorinfo;

      if(phone === "")
      {
        haserror    = true;
        temperrorinfo   = {...temperrorinfo, phonemsg:"Please enter phone number",hasphoneerror:true};
      }
      else if(phone !== "" && !this.check_phone(phone))
      {
        haserror    = true;
        temperrorinfo   = {...temperrorinfo, phonemsg:"Please enter valid phone number",hasphoneerror:true};
      }
      else
      {
        temperrorinfo   = {...temperrorinfo, phonemsg:"",hasphoneerror:false};
      }

      if(haserror)
      {
        this.setState({
            errorinfo:temperrorinfo
        });
        return false;
      }
      else
      {
        temperrorinfo   = {...temperrorinfo, phonemsg:"", hasphoneerror:false};
        this.setState({
            errorinfo:temperrorinfo
        });
      }

      this.setState(()=>{
        return{
          message:'Processing...',
          alertcolor:'primary',
          isprocessing:true
        }
      },()=>{
        setTimeout(()=>{
          const bodyFormData = new FormData();
          bodyFormData.set('Mode', 'SendResetPasswordOtp');
          bodyFormData.set('phone', phone);
          bodyFormData.set('smscode', smscode);
    
          axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}resetpassword.php`,
              data: bodyFormData,
              config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(response=>{

              this.setState({
                isprocessing:false,
                message:'',
              });

              let color   = "danger";
              
              if(response.data.success === true)
              {
                localStorage.setItem(`orlo_${process.env.REACT_APP_PREFIX}_users`,JSON.stringify(response.data.detail));

                color   = "success";
  
                /*this.props.history.push(`${response.data.redirecturl}`);*/
              }

              /*this.showMessage(response.data.msg,color);*/

            })
            .catch(function (response) {
              //handle error
              console.log(response);
          });

        },1500);
      })
    }

	render(){

        const{ errorinfo, phone, smscode } = this.state;

      return (
         <ThemeConsumer>{(value)=>{
               
            const{changePopupTypeWidth} = value;

            return (
                <Fragment>
                <div className="common_RowTitle_3YpeR common_Row_mq_ng">Password recovery</div>
                    <form method="post" onSubmit={this.handleSubmit}>
                        <div className="common_Row_mq_ng">
                            <div className={`Input_Root_2mmBV ${phone !== "" ?`Input_Filled_eU9fm Input_WithPrefix_3ymyc`:``} ${errorinfo.hasphoneerror ? `Input_error_i-YyB dv-scroll-error-marker`:''}`}>
                            <div className="Input_Wrapper_g4Kqw">
                                <div className="Input_InputWrapper_3N0O5">
                                    {phone !== "" ? <div className="Input_Prefix_AFgXf">+91</div>:null}
                                    <input className="Input_Field_3rwEh unique-registration-person-modal__send-code unique-registration-person-modal__phone-no-verified" autoComplete="username" inputMode="tel" placeholder="phone" name="phone" id="phone" value={phone} onChange={this.handleUserInput} /><label className="Input_Label_2fEDE">Phone</label>
                                </div>
                                <div className="Input_PostfixWrapper_3z_9d"><span className="Button_Wrapper_1jsIr"><button className="Button_Button_1tpBy Button_ButtonSmall_zYd6D Button_Primary_2qt9c Button_Rect_3x530" type="submit" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}}><span className="Button_Content_2MgbS">Get code</span></button></span></div>
                            </div>
                            {
                            errorinfo.phonemsg ? (<span class="InlineError_Root_19Zvx">{errorinfo.phonemsg}</span>):''
                            }
                            </div>
                        </div>
                        <div className="common_Row_mq_ng">
                            <div className={`Input_Root_2mmBV ${smscode !== "" ?`Input_Filled_eU9fm`:``} Input_disabled_3k7BL`}>
                            <div className="Input_Wrapper_g4Kqw">
                                <div className="Input_InputWrapper_3N0O5"><input disabled="" className="Input_Field_3rwEh unique-registration-person-modal__code" type="tel" autoComplete="one-time-code" name="smscode" id="smscode" value={smscode} onChange={this.handleUserInput}/><label className="Input_Label_2fEDE">SMS-code</label></div>
                            </div>
                            </div>
                        </div>
                    </form>
                    <div className="common_Buttons_1sjHn"><span className="Button_Wrapper_1jsIr"><button className="Button_Button_1tpBy common_Button_17HA4 Button_ButtonMedium_3seGY Button_Link_gDHo3" type="button" style={{'--color':'hsl(223,100%,50%)','--color-hover':'hsl(223,100%,35%)'}} onClick={()=>{changePopupTypeWidth('login','450px')}}><span className="Button_Content_2MgbS">I remember old password</span></button></span></div><button
                    className="react-responsive-modal-closeButton Modal_CloseButton_1ebrY unique-modal-close"
                    data-testid="close-button"><svg width="28" height="28" viewBox="0 0 36 36" data-testid="close-icon">
                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
                    </svg></button>
                </Fragment>
            );
         }}
         </ThemeConsumer>
      )
	}
}
import React from "react";
import { icons, images } from '../constants';

const Footer=()=> {
    return (
        <footer className="layout__footer">
            <div className="dv-footer dv-footer--dark-theme">
                <div className="dv-footer__wrapper">
                    <div className="dv-footer__left-column">
                        <div className="dv-footer__logo"> <a href="/"><img src={icons.logowhite}
                                    alt="logo" /></a> </div>
                        <ul className="dv-footer-menu__items">
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="20"> <a href="/in/mumbai"> Mumbai </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="22"> <a href="/in/delhi"> Delhi/NCR </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="21"> <a href="/in/bengaluru"> Bengaluru </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="23"> <a href="/in/hyderabad"> Hyderabad </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="24"> <a href="/in/ahmedabad"> Ahmedabad </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="25"> <a href="/in/chennai"> Chennai </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="26"> <a href="/in/kolkata"> Kolkata </a> </li>
                            <li className="dv-footer-menu__item region-switcher__item dv-footer-menu__item-global"
                                data-id="28"> <a href="/in/pune"> Pune </a> </li>
                        </ul>
                    </div>
                    <div className="dv-footer__right-column">
                        <div className="dv-footer__menu_and_contacts">
                            <ul className="dv-footer__menu">
                                <li><a href="/in/plans">Subscription Plans</a></li>
                                <li><a href="/in/order-store-to-go">Buy from Store</a></li>
                                <li><a href="/in/business">For Businesses</a></li>
                                <li><a href="/in/privacy-policy">Cookie Statement</a></li>
                                <li><a href="/in/api-integration">API Integration</a></li>
                                <li><a href="/in/privacy-policy">Privacy Policy</a><br /></li>
                                <li><a href="/in/faq">FAQs</a></li>
                                <li><a href="/in/about">About</a></li>
                                <li><a href="/in/contacts">Contacts</a></li>
                                <li><a href="/in/disclaimers-and-dispute-resolution">Disclaimers &amp; Dispute
                                        Resolution</a></li>
                                <li><a href="/in/track">Track Order</a></li>
                                <li><a href="/in/refund-and-cancellation">Refund and Cancellation</a></li>
                                <li><a href="/in/cities">Courier Service in Mumbai</a></li>
                                <li><a href="/in/community-guidelines">Community Guidelines</a></li>
                                <li><a href="/in/tariffs">Pricing</a></li>
                                <li><a href="/in/covid-19">Covid 19 - Clients</a></li>
                                <li><a href="/in/terms-and-conditions">Terms and conditions</a></li>
                            </ul>
                            <div className="dv-footer__contacts"> </div>
                        </div>
                    </div>
                    <div className="dv-footer__app-buttons"> <a
                            href="#"
                            target="_blank"><img src={images.appstore} title="appstore" /></a><a
                            href="#"
                            target="_blank"><img src={images.playstore} title="playstore" /></a><a
                            href="#" target="_blank"><img
                                src={images.appgallery} title="appgallery" /></a> </div>
                </div>
            </div>
            <div className="dv-footer-bottom dv-footer-bottom--dark-theme">
                <div className="dv-footer-bottom__wrapper">
                    <div className="dv-footer-bottom__left"> <a className="dv-footer-bottom__facebook"
                            href="#" title="facebook" target="_blank"></a><a
                            className="dv-footer-bottom__instagram" href="#"
                            title="instagram" target="_blank"></a><a className="dv-footer-bottom__youtube"
                            href="#" title="youtube"
                            target="_blank"></a> </div>
                    <div className="dv-footer-bottom__right"> © 2017–2021 Orlo Delivery. All Rights reserved. </div>
                </div>
            </div>
        </footer>
    );
  }

  export default Footer;
import React,{Component} from 'react';

const ThemeContext = React.createContext();
//Provider
//Consumer
/*jshint esversion: 6 */

class ThemeProvider extends Component{

	state = {
		isregionpopup:false,
		isloginsignuppopup:false,
		ishomesitepopup:false,
		popuptype:'login',
		popupwidth:'450px',
	}

    toggleRegionpopup=()=>{
        this.setState({
            isregionpopup:!this.state.isregionpopup
        });
    }
	
    toggleLoginSignupPopup=()=>{
        this.setState({
            isloginsignuppopup:!this.state.isloginsignuppopup
        });
    }

    toggleHomeSitePopup=(isvisible)=>{

        this.setState({
            /*ishomesitepopup:!this.state.ishomesitepopup,*/
            ishomesitepopup:isvisible
        });
    }

    changePopupTypeWidth=(popuptype, popupwidth)=>{

        this.setState({
            popuptype:popuptype,
            popupwidth:popupwidth,
        });
    }

	render(){
		return (
			<ThemeContext.Provider value={{
			...this.state,
				toggleRegionpopup:this.toggleRegionpopup,
				toggleLoginSignupPopup:this.toggleLoginSignupPopup,
				toggleHomeSitePopup:this.toggleHomeSitePopup,
				changePopupTypeWidth:this.changePopupTypeWidth,
			}}
			>
			{this.props.children}
			</ThemeContext.Provider>
		);
	}
}

const ThemeConsumer = ThemeContext.Consumer;

export {ThemeProvider, ThemeConsumer, ThemeContext};
import React,{Component} from 'react';
import { Modal } from 'react-responsive-modal';
import LoginPopup from './LoginPopup';
import SignupPopup from './SignupPopup';
import ForgotPasswordPopup from './ForgotPasswordPopup';
import { ThemeContext, ThemeConsumer } from '../context/Theme';

export default class Sitepopup extends Component{

   static contextType = ThemeContext;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){

    }

	render(){

      return (
         <ThemeConsumer>{(value)=>{
               
            const{toggleHomeSitePopup} = value;
            const{ishomesitepopup, popuptype, popupwidth} = value;

            console.log(popuptype);

            return (
                <Modal open={ishomesitepopup} onClose={()=>{toggleHomeSitePopup(false)}} classNames={{
                overlay: 'Modal_Overlay_1DSkx',
                modalContainer: 'Modal_ModalContainer_2VcT3',
                modal: 'Modal_ModalBlock_1MOPc SignInSignUpModal_Modal_2CvDu',
                closeButton:'Modal_CloseButton_1ebrY'
                }}
                styles={{
                modal:{animation:'0ms ease 0s 1 normal none running react-responsive-modal-modal-in', width:popupwidth}
                }}>
                    {(()=>{
                        if(popuptype === 'login')
                        {
                            return <LoginPopup />
                        }
                        else if(popuptype === 'signup')
                        {
                            return <SignupPopup />
                        }
                        else if(popuptype === 'forgotpassword')
                        {
                            return <ForgotPasswordPopup />
                        }
                    })()}
                </Modal>
            );
         }}
         </ThemeConsumer>
      )
	}
}